import { Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ContentLoader from '../subcomponents/ContentLoader';
import FilterButton from '../subcomponents/FilterButton';
import FilterSidebar from '../subcomponents/FilterSidebar';
import PageLayout from '../subcomponents/PageLayout';
import ContactCard from './ContactCard';
import { fetchContacts } from './contactSlice';

export default function ContactPage() {
    const dispatch = useDispatch();
    const { contacts, contactsStatus } = useSelector((state) => state.contacts);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { student } = useSelector((state) => state.student);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const [filter, setFilter] = useState("");
    const [mentor, setMentor] = useState(siomSettings.mentorAsContact);

    useEffect(() => {
        if (student !== null) {
            dispatch(fetchContacts(student?.userId));
        };
    }, [dispatch, student]);

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setFilter(value);
        }, 600);
    };

    const mentorClick = () => {
        setMentor(!mentor);
    };

    var customFilterFunction = (item) => {
        return item.isMentor === mentor &&
            (!mentor ? item.isContact === true : item.isContact === true || item.isContact === false) &&
            ((item.education !== null && item.education?.toLowerCase().includes(filter.toLowerCase())) ||
                item.firstname?.toLowerCase().includes(filter.toLowerCase()) ||
                item.insertion?.toLowerCase().includes(filter.toLowerCase()) ||
                item.lastname?.toLowerCase().includes(filter.toLowerCase()) ||
                item.email?.toLowerCase().includes(filter.toLowerCase()) ||
                item.phone?.toLowerCase().includes(filter.toLowerCase()));
    };

    const FilterFragment = () => {
        return (
            <>
                {siomSettings.mentorAsContact ?
                    <FormGroup sx={{ width: 1 }}>
                        <FormControlLabel sx={{ alignSelf: 'center' }} control={<Checkbox checked={mentor} onChange={mentorClick} />} label={`${resourceFinal[appLanguage].messages.SHOW_MENTOR}`} />
                    </FormGroup>
                    : ""}
            </>
        );
    };

    return (
        <PageLayout title={resourceFinal[appLanguage].words.CONTACT} content={
            <ContentLoader successCondition={contactsStatus === "success"} errorCondition={contactsStatus === "failed"} content={
                <>
                    <Grid container spacing={1} justifyContent="center">
                        {contactsStatus === "success" ?
                            contacts.filter(customFilterFunction).length > 0 ? contacts.filter(customFilterFunction).map((item) =>
                                <Grid item key={item.id} xs={12} md={4}>
                                    <ContactCard contact={item} />
                                </Grid>
                            ) : <Grid item xs><Typography variant="h6" textAlign="center">{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</Typography></Grid> : ""}
                    </Grid>
                </>
            } />
        } contentLeft={
            <FilterSidebar searchFunc={handleSearchChange} filters={<FilterFragment />} />
        } bottomBar searchFunc={handleSearchChange} contentBottom={
            siomSettings.mentorAsContact ? <FilterButton filters={<FilterFragment />} /> : ""
        } />
    );
}
