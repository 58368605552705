import SearchIcon from '@mui/icons-material/Search';
import { AppBar, Box, Grid, IconButton, Stack, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { PageContainer, PageHeader, PageRoot } from '../../app/AppStyles';
import BackButton from './BackButton';
import SearchField from './SearchField';

export default function PageLayout({ content, contentLeft, contentRight, contentBottom, bottomBar, searchFunc, title, titleLower, subtitle, style, fullScreen }) {
    const textRef = useRef(null);
    const scrollRef = useRef(null);
    const desktop = useMediaQuery('(min-width:900px)');

    const [openSearch, setOpenSearch] = useState(false);

    const bottomBgcolor = theme => `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main} 95%)`;

    // Open or close search bar
    const handleToggle = () => {
        // Scroll to top if search is open
        if (openSearch && scrollRef.current.viewScrollTop > 0) {
            scrollRef.current.scrollTop(0);
            setTimeout(() => textRef.current.focus(), 100);
            return;
        };

        // Cant close search if there is a value
        if ((openSearch && textRef.current.value === "") || !openSearch)
            setOpenSearch(!openSearch);
        else
            setTimeout(() => textRef.current.focus(), 100);
    };

    useEffect(() => {
        if (openSearch)
            setTimeout(() => textRef.current.focus(), 100);
    }, [openSearch]);

    return (
        <>
            <Grid container sx={{ ...PageRoot }}>
                <Grid item xs={0} md={fullScreen ? 0 : 2} sx={{ display: { xs: 'none', md: 'flex' } }}>{contentLeft}</Grid>
                <Grid item xs={12} md={fullScreen ? 12 : 8} sx={{ ...PageContainer, ...style }}>
                    <Scrollbars ref={scrollRef} autoHide autoHideTimeout={1000} autoHideDuration={200}>
                        <Stack sx={{ display: 'flex', minHeight: 1 }}>
                            {title && desktop &&
                                <Stack>
                                    <Typography sx={{ ...PageHeader, typography: { xs: 'h5', md: 'h3' } }}>{title} {titleLower && titleLower.toLowerCase()}</Typography>
                                    {subtitle && <Typography sx={{ ...PageHeader, typography: { xs: 'h6', md: 'h4' } }}>{subtitle}</Typography>}
                                </Stack>}
                            {searchFunc && openSearch && <Box sx={{ mb: 1 }}><SearchField func={searchFunc} textRef={textRef} /></Box>}
                            {content}
                        </Stack>
                    </Scrollbars>
                </Grid>
                <Grid item xs={0} md={fullScreen ? 0 : 2} sx={{ display: { xs: 'none', md: 'flex' } }}>{contentRight}</Grid>
            </Grid>
            {bottomBar && !desktop &&
                <AppBar position="static" color="primary" sx={{ top: 'auto', bottom: 0, height: 48, backgroundImage: bottomBgcolor }}>
                    <Toolbar sx={{ display: 'flex', minHeight: 1 }}>
                        <Typography sx={{ typography: 'h6', color: 'white.main', flexGrow: 1 }}>{title} {titleLower ? titleLower.toLowerCase() : ""}</Typography>
                        {contentBottom}
                        {searchFunc &&
                            <IconButton onClick={handleToggle}>
                                <SearchIcon sx={{ color: 'white.main', fontSize: 32 }} />
                            </IconButton>}
                        <BackButton />
                    </Toolbar>
                </AppBar>}
        </>
    )
}