import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Modal, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, alpha } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MainBorderRadius, TableHeader } from '../../../app/AppStyles';
import ConfirmationModal from '../../subcomponents/ConfirmationModal';
import ContentLoader from '../../subcomponents/ContentLoader';
import SearchField from '../../subcomponents/SearchField';
import TablePaginationActions from '../../subcomponents/TablePaginationActions';
import { setAlertMessage, setAlertSeverity, setAlertStatus } from '../../subcomponents/alertSlice';
import LinkProgramAddCard from './LinkProgramAddCard';
import { getAllProgramLinks, getDepartmentsToLink, getProgramsToLink, putProgramLink, resetItemsToLink, resetProgramLinkStatus, setFailedProgramLink } from './linkProgramSlice';

export default function LinkProgramTab() {
    const dispatch = useDispatch();
    const [openNew, setOpenNew] = useState(false);
    const [filter, setFilter] = useState("");
    const textRef = useRef(null);

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { programLinks, programLinksStatus, putProgramLinkStatus } = useSelector((state) => state.management.linkProgram);

    useEffect(() => {
        dispatch(getAllProgramLinks());
    }, [dispatch]);

    useEffect(() => {
        if (putProgramLinkStatus === "success") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.DELETE_SUCCESS}`));
            dispatch(setAlertSeverity("success"));
            dispatch(resetProgramLinkStatus());
            dispatch(getAllProgramLinks());
        } else if (putProgramLinkStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.ERROR_DELETE_FAILED}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetProgramLinkStatus());
        };
    }, [dispatch, resourceFinal, appLanguage, putProgramLinkStatus]);

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setFilter(value);
        }, 600);
    };

    var customFilterFunction = (item) => {
        return item.program.name?.toLowerCase().includes(filter.toLowerCase());
    };

    const newClick = () => {
        dispatch(setFailedProgramLink(false));
        dispatch(getProgramsToLink());
        dispatch(getDepartmentsToLink());
        setOpenNew(true);
    };

    const callbackModalNew = () => {
        setOpenNew(false);
        setFilter("");
        dispatch(resetItemsToLink());
        dispatch(resetProgramLinkStatus());
        dispatch(setFailedProgramLink(false));
    };

    const TableFragment = () => {
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(10);

        const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - programLinks?.filter(customFilterFunction).length) : 0;

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        const deleteClick = (item) => {
            dispatch(putProgramLink(item));
        };

        return (
            <>
                <TableBody>
                    {(rowsPerPage > 0 ? programLinks.filter(customFilterFunction).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : programLinks.filter(customFilterFunction)).map((item, index) =>
                        <TableRow key={index} sx={{ '&:nth-of-type(odd)': { bgcolor: 'white.main' }, height: 63 }}>
                            <TableCell align="left">{item?.program?.name}</TableCell>
                            {siomSettings?.useMultipleDepartments && <TableCell align="center">{item?.department?.name}</TableCell>}
                            <TableCell align="center">{item?.linkedItem?.linkedName}</TableCell>
                            <TableCell align="center">{item?.linkType}</TableCell>
                            <TableCell align="center" padding="checkbox">
                                <ConfirmationModal iconButton={true} icon={<DeleteIcon color="secondary" />} message={`${resourceFinal[appLanguage].messages.DELETE_CONFIRMATION_1} ${item?.program?.name} ${resourceFinal[appLanguage].words.LINK.toLowerCase()} ${resourceFinal[appLanguage].messages.DELETE_CONFIRMATION_2}`} confirmAction={() => deleteClick(item)} />
                            </TableCell>
                        </TableRow>
                    )}
                    {(rowsPerPage > 0 ? programLinks.filter(customFilterFunction).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : programLinks.filter(customFilterFunction)).length === 0 && <TableRow><TableCell colSpan={13}>{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</TableCell></TableRow>}
                    {emptyRows > 0 && <TableRow style={{ height: 63 * emptyRows }}><TableCell colSpan={13} /></TableRow>}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            sx={{ border: 0 }}
                            rowsPerPageOptions={[5, 10, 20]}
                            count={programLinks?.filter(customFilterFunction).length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                            labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                        />
                    </TableRow>
                </TableFooter>
            </>
        );
    };
    return (
        <>
            <ContentLoader successCondition={programLinksStatus === "success"} errorCondition={programLinksStatus === "failed"} content={
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', bgcolor: theme => alpha(theme.palette.primary.light, 0.3), borderRadius: MainBorderRadius, p: 1 }}>
                        <SearchField func={handleSearchChange} textRef={textRef} />
                        <Button variant="outlined" onClick={newClick} startIcon={<AddIcon />}>{resourceFinal[appLanguage].words.ADD}</Button>
                    </Box>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.PROGRAM}{resourceFinal[appLanguage].words.NAME.toLowerCase()}</TableCell>
                                    {siomSettings?.useMultipleDepartments && <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.DEPARTMENT}</TableCell>}
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.LINKED}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.LINK}{resourceFinal[appLanguage].words.TYPE.toLowerCase()}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableFragment />
                        </Table>
                    </TableContainer>
                </Box>
            } />

            <Modal open={openNew} onClose={callbackModalNew}>
                <>
                    <LinkProgramAddCard handleClose={callbackModalNew} />
                </>
            </Modal>
        </>
    );
}
