import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Button, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { GetTime } from '../../app/AppUtils';

export default function TimetableDisplay({ timetable, employeeMode }) {
    var calendarRef = React.createRef();
    const desktopDisplay = useMediaQuery('(min-width:900px)');

    const [calendarApi, setCalendarApi] = useState();
    const [title, setTitle] = useState();

    const { timetableStatus, employeeTimetableStatus } = useSelector((state) => state.timetable);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    useEffect(() => {
        if (calendarRef !== null) {
            const status = employeeMode ? employeeTimetableStatus : timetableStatus;
            if (status === 'success') {
                setCalendarApi(calendarRef.current.getApi());
            }
        }
    }, [calendarRef, employeeMode, timetableStatus, employeeTimetableStatus]);

    useEffect(() => {
        if (calendarApi !== null && timetableStatus === 'success') {
            setTitle(calendarApi?.view.title);
        }
    }, [calendarApi, timetableStatus]);

    const nextClick = () => {
        calendarApi.next();
        setTitle(calendarApi.view.title);
    };

    const prevClick = () => {
        calendarApi.prev();
        setTitle(calendarApi.view.title);
    };

    const todayClick = () => {
        calendarApi.today();
        setTitle(calendarApi.view.title);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', typography: 'body1' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                <Button color="secondary" sx={{ borderRadius: 1, border: 0.5 }} onClick={() => todayClick()}>{resourceFinal[appLanguage].words.TODAY}</Button>
                <Typography sx={{ typography: { xs: "body1", md: "h6" }, textAlign: 'center' }}>{title}</Typography>
                <Stack direction="row" spacing={1}>
                    <IconButton color="secondary" sx={{ borderRadius: 1, border: 0.5 }} onClick={() => prevClick()}>
                        <NavigateBeforeIcon />
                    </IconButton>
                    <IconButton color="secondary" sx={{ borderRadius: 1, border: 0.5 }} onClick={() => nextClick()}>
                        <NavigateNextIcon />
                    </IconButton>
                </Stack>
            </Box>
            <Box sx={{ flex: '1 1 auto' }}>
                <FullCalendar
                    ref={calendarRef}
                    firstDay={1}
                    weekends={false}
                    headerToolbar={false}
                    events={timetable}
                    eventDisplay="block"
                    displayEventEnd={true}
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    locale="nl-NL"
                    height="100%"

                    eventContent={
                        function (arg) {
                            var event = arg.event;
                            var customHtml = '';

                            if (desktopDisplay) {
                                customHtml = `<span style="font-weight: bold;">${GetTime(event.start)} - ${GetTime(event.end)} | ${event.title}</span>`
                            } else {
                                customHtml = `<Tooltip title="${GetTime(event.start)} - ${GetTime(event.end)} | ${event.title}"><span style="font-weight: bold;">${GetTime(event.start)} ...</span></Tooltip>`
                            }

                            return { html: customHtml }
                        }
                    }
                />
            </Box>
        </Box>
    );
}