import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../api-authorization/AuthorizeService";

export const fetchTimetable = createAsyncThunk(
    '/api/timetable/user',
    async (departmentId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const urlParams = departmentId !== null ? `api/timetable/user/${departmentId}` : `api/timetable/user`;
        const response = await fetch(window.Config.apiUrl + urlParams, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchTimetableOverview = createAsyncThunk(
    '/api/timetable/overview',
    async (departmentIds, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/timetable/overview`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify({
                departmentIds: departmentIds
            }),
        });

        if (!response.ok) {
            const errorCode = await response.json();
            return rejectWithValue(errorCode);
        } else {
            return response.json();
        };
    }
);

export const fetchTimetableForEmployee = createAsyncThunk(
    '/api/timetable/employee',
    async ({ studentId, departmentId }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const urlParams = departmentId !== null ? `api/timetable/employee/${studentId}/${departmentId}` : `api/timetable/employee/${studentId}`;
        const response = await fetch(window.Config.apiUrl + urlParams, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);


export const timetableSlice = createSlice({
    name: 'timetable',
    initialState: {
        timetable: null,
        timetableStatus: null,
        timetableOverview: null,
        timetableOverviewStatus: null,
        employeeTimetable: null,
        employeeTimetableStatus: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        // Fetch Timetable
        builder
            .addCase(fetchTimetable.pending, (state) => {
                state.timetableStatus = 'loading';
            })
            .addCase(fetchTimetable.fulfilled, (state, action) => {
                state.timetableStatus = 'success';
                state.timetable = action.payload;
            })
            .addCase(fetchTimetable.rejected, (state) => {
                state.timetableStatus = 'failed';
            });

        // Fetch Timetable for employee (Search)
        builder
            .addCase(fetchTimetableForEmployee.pending, (state) => {
                state.employeeTimetableStatus = 'loading';
            })
            .addCase(fetchTimetableForEmployee.fulfilled, (state, action) => {
                state.employeeTimetableStatus = 'success';
                state.employeeTimetable = action.payload;
            })
            .addCase(fetchTimetableForEmployee.rejected, (state) => {
                state.employeeTimetableStatus = 'failed';
            });

        // Fetch Timetable Overview
        builder
            .addCase(fetchTimetableOverview.pending, (state) => {
                state.timetableOverviewStatus = 'loading';
            })
            .addCase(fetchTimetableOverview.fulfilled, (state, action) => {
                state.timetableOverviewStatus = 'success';
                state.timetableOverview = action.payload;
            })
            .addCase(fetchTimetableOverview.rejected, (state) => {
                state.timetableOverviewStatus = 'failed';
            });
    }

});

//export const { } = timetableSlice.actions;

export default timetableSlice.reducer;