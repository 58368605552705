import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchActivities } from '../activity/activitySlice';
import BackButton from '../subcomponents/BackButton';
import ContentLoader from '../subcomponents/ContentLoader';
import FilterButton from '../subcomponents/FilterButton';
import FilterSidebar from '../subcomponents/FilterSidebar';
import PageLayout from '../subcomponents/PageLayout';
import FavoriteCard from './FavoriteCard';
import FavoritesCheckMessage from './FavoritesCheckMessage';

export default function FavoritesPage() {
    const dispatch = useDispatch();

    const { activities, activitiesStatus, scrollPosition } = useSelector((state) => state.activity);
    const { addStatus, removeStatus } = useSelector((state) => state.favorites);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { activeDepartment, departmentThemesStatus } = useSelector((state) => state.settings.theme);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { student } = useSelector((state) => state.student);
    const [filter, setFilter] = useState("");
    const [onlyFree, setOnlyFree] = useState(false);

    useEffect(() => {
        if (student !== null) {
            if (siomSettings.useMultipleDepartments) {
                if (activeDepartment !== null) {
                    dispatch(fetchActivities(activeDepartment[0].id));
                }
            } else {
                dispatch(fetchActivities(null));
            }
        }
    }, [dispatch, student, addStatus, removeStatus, departmentThemesStatus, activeDepartment, siomSettings])

    useEffect(() => {
        if (activitiesStatus === "success") {
            window.scrollTo(0, parseInt(scrollPosition))
        }
    }, [activitiesStatus, scrollPosition])

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setFilter(value);
        }, 600);
    };

    const handleChange = () => {
        setOnlyFree(!onlyFree);
    };

    const FilterFragment = () => {
        return (
            <FormGroup>
                <FormControlLabel control={<Checkbox checked={onlyFree} onChange={handleChange} />} label={`${resourceFinal[appLanguage].messages.FILTER_ONLY_FREE}`} />
            </FormGroup>
        );
    };

    var customFilterFunction = (item) => {
        return (
            item.name?.toLowerCase().includes(filter.toLowerCase()) && (onlyFree ? item.cost === 0 : item.cost >= 0)
        );
    };

    return (
        <PageLayout title={`${resourceFinal[appLanguage].words.FAVORITES}`} titleLower={`${resourceFinal[appLanguage].words.MANAGE}`} favoritePage={true} content={
            <ContentLoader successCondition={activitiesStatus === "success"} errorCondition={activitiesStatus === "failed"} content={
                <>
                    <FavoritesCheckMessage />
                    <Grid container spacing={{ xs: 1, md: 2 }} sx={{ display: 'flex', justifyContent: 'center' }}>
                        {activitiesStatus === "success" ?
                            activities.filter(customFilterFunction).length > 0 ? activities.filter(customFilterFunction).map((item) =>
                                <Grid item key={item.id}>
                                    <FavoriteCard favoritePage={false} itemId={item.id} activityId={item.id} name={item.name} imageUrl={item.imageUrl} userFavorite={item.userFavorite} />
                                </Grid>
                            ) : <Grid item xs><Typography variant="h6" textAlign="center">{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</Typography></Grid> : ""}
                        {activitiesStatus === "success" ? Object.keys(activities).length === 0 ? <Typography variant="h6" sx={{ mt: 10 }}>{resourceFinal[appLanguage].messages.NO_CURRENT_CLINICS}</Typography> : "" : ""}
                    </Grid>
                </>
            } />
        } contentLeft={
            <FilterSidebar searchFunc={handleSearchChange} filters={<FilterFragment />} />
        } bottomBar searchFunc={handleSearchChange} contentBottom={
            <FilterButton filters={<FilterFragment />} />
        } contentRight={
            <Box sx={{ display: 'flex', flex: '1 1 auto', justifyContent: 'flex-end' }}>
                <BackButton desktop />
            </Box>
        } />
    );
}
