import { Card } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { CardMedium } from '../../../app/AppStyles';
import ContentLoader from '../../subcomponents/ContentLoader';
import TimetableDisplay from '../../timetable/TimetableDisplay';
import { fetchTimetableForEmployee } from '../../timetable/timetableSlice';

export default function SearchStudentTimetableTab() {
    const dispatch = useDispatch();

    const { employeeTimetable, employeeTimetableStatus } = useSelector((state) => state.timetable);
    const { activeDepartment } = useSelector((state) => state.settings.theme);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { searchStudent } = useSelector((state) => state.search);

    useEffect(() => {
        if (siomSettings?.useMultipleDepartments) {
            if (activeDepartment !== null) {
                dispatch(fetchTimetableForEmployee({ studentId: searchStudent?.id, departmentId: activeDepartment[0].id }));
            };
        } else {
            dispatch(fetchTimetableForEmployee({ studentId: searchStudent?.id, departmentId: null }));
        };
    }, [dispatch, activeDepartment, searchStudent, siomSettings]);

    return (
        <Card elevation={0} sx={{ ...CardMedium, display: 'flex' }}>
            <ContentLoader successCondition={employeeTimetableStatus === 'success'} errorCondition={employeeTimetableStatus === 'failed'} content={
                <TimetableDisplay timetable={employeeTimetable} employeeMode />
            } />
        </Card>
    );
}
