import CreateIcon from '@mui/icons-material/Create';
import FavoriteIcon from '@mui/icons-material/Favorite';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Card, CardActionArea, CardActions, CardContent, CardMedia, IconButton, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { CardSmall } from '../../app/AppStyles';
import { setStudentRegisterPage } from '../search/searchSlice';
import { setPerformances } from './registerSlice';

export default function RegisterCard({ performance, employeeMode, reasonMode, futureWarning }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { register, registerAll } = useSelector((state) => state.register);
    const { studentRegisterReasonMode } = useSelector((state) => state.search);

    const PerformancesClick = (name, id) => {
        dispatch(setPerformances((studentRegisterReasonMode ? registerAll : register).find(item => item.activityId === id).activityPerformances));
        if (employeeMode)
            dispatch(setStudentRegisterPage('PERFORMANCE'));
        else
            navigate(`/inschrijven/uitvoeringen/${encodeURIComponent(name)}/${id}`);
    };

    const InfoClick = (name, id) => {
        if (employeeMode)
            navigate(`/zoeken/activiteit/${encodeURIComponent(name)}/${id}`);
        else
            navigate(`/activiteit/${encodeURIComponent(name)}/${id}`);
    };

    return (
        <Card sx={{ ...CardSmall }}>
            <CardActionArea onClick={() => PerformancesClick(performance.activityName, performance.activityId)}>
                <Box sx={{ display: performance.isFavorite ? 'flex' : 'none', backgroundColor: 'white.main', position: 'absolute', top: 0, lef: 0, p: 0.1, borderRadius: '8px 0px' }}>
                    <FavoriteIcon sx={{ color: 'secondary.main', m: 0.1 }} />
                </Box>
                <CardMedia component="img" sx={{
                    height: { xs: 90, md: 130 }, opacity: reasonMode ? (performance.anyValidStatus ? 1 : 0.5) : 1
                }} image={performance.imageUrl ? performance.imageUrl : '/imgs/Placeholder.png'} alt="activity image" />
                <CardContent sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', p: 0.5 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {performance.activityName}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions sx={{ display: 'flex', justifyContent: 'center', p: 0 }}>
                {(reasonMode && !performance.anyValidStatus) &&
                    <IconButton size="small" disabled>
                        <WarningIcon color="error" />
                    </IconButton>}
                {(futureWarning && performance.anyOutsideProgram) &&
                    <IconButton size="small" disabled>
                        <WarningIcon color="warning" />
                    </IconButton>}
                <IconButton size="small" onClick={() => InfoClick(performance.activityName, performance.activityId)}>
                    <InfoIcon color="secondary" />
                </IconButton>
                <IconButton size="small" onClick={() => PerformancesClick(performance.activityName, performance.activityId)}>
                    <CreateIcon color="secondary" />
                </IconButton>
            </CardActions>
        </Card>
    );
}
